import React, { useRef, useEffect } from 'react';
import { WithStore, Slider, Slide } from 'pure-react-carousel';
import SVGInline from 'react-svg-inline';
import styled from '@emotion/styled';
import useUserContext from 'src/context/UserContext';
import { link, barChart, messageCircle, team } from '@sizle-icons';

const OnboardingSlider = ({ dismissModal }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      dismissModal();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SliderContainer ref={modalRef}>
      <ExpandedGlow>
        <Slider
          isIntrinsicHeight={true}
          isPlaying={false}
          infinite={false}
          dragEnabled={false}
          touchEnabled={false}
        >
          <Slide index={0}>
            <div className="slide">
              <h2 className="slide-title">Let’s get started!</h2>
              <h3 className="slide-subtitle">
              Send proposals, capture leads and track engagement all in one platform - designed to close deals quickly.
              </h3>
              <div className="slide-body">
                <RecommendationList>
                  <Recommendation>
                    <SVGInline
                      svg={link
                        .replace(/fill=".*?"/g, '')
                        .replace(
                          /<path/g,
                          '<path stroke="#6a5acd" fill="none" stroke-width="2"'
                        )}
                      className="icon"
                    />
                    <TextContainer>
                      See prospects interact with collateral
                    </TextContainer>
                  </Recommendation>
                  <Recommendation>
                    <SVGInline
                      svg={barChart
                        .replace(/fill=".*?"/g, '')
                        .replace(
                          /<path/g,
                          '<path stroke="#6a5acd" fill="none" stroke-width="2"'
                        )}
                      className="icon"
                    />
                    <TextContainer>
                      Focus on high-intent prospects
                    </TextContainer>
                  </Recommendation>
                  <Recommendation>
                    <SVGInline
                      svg={messageCircle
                        .replace(/fill=".*?"/g, '')
                        .replace(
                          /<path/g,
                          '<path stroke="#6a5acd" fill="none" stroke-width="2"'
                        )}
                      className="icon"
                    />
                    <TextContainer>
                      Collect email leads automatically
                    </TextContainer>
                  </Recommendation>
                  <Recommendation>
                    <SVGInline
                      svg={team
                        .replace(/fill=".*?"/g, '')
                        .replace(
                          /<circle/g,
                          '<circle stroke="#6a5acd" fill="none" stroke-width="2"'
                        )
                        .replace(
                          /<path/g,
                          '<path stroke="#6a5acd" fill="none" stroke-width="2"'
                        )}
                      className="icon"
                    />
                    <TextContainer>
                      Share and approve content effortlessly
                    </TextContainer>
                  </Recommendation>
                </RecommendationList>
              </div>
              <div className="btn-group">
                <button className="btn enhanced-btn" onClick={dismissModal}>
                  Go to your dashboard
                </button>
              </div>
            </div>
          </Slide>
        </Slider>
      </ExpandedGlow>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding: 20px 0 0 0; /* Removed bottom padding */
  border-radius: 16px;
  overflow: hidden;

  .carousel__slide-focus-ring {
    outline: none;
    border: none;
  }
  .carousel__slider--horizontal {
    outline: none;
    height: auto;
  }
  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:35px;
  }
  .slide-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 1rem;
  }
  .slide-subtitle {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--text-color);
    text-align: center;
    line-height: 1.4;
    padding: 10px 0px 5px;
  }
  .slide-body {
    width: 100%;
    text-align: left;
    padding: 0 15px;
  }
  .enhanced-btn {
    background: linear-gradient(135deg, #5d55fa, #3e38ae);
    color: #ffffff;
    padding: 14px 28px;
    font-size: 16px;
    border-radius: 8px;
    margin-top: 2em;
    border: none;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.3s;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  .enhanced-btn:hover {
    transform: translateY(-2px);
  }
  .enhanced-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(93, 85, 250, 0.3);
  }

  @media (max-width: 768px) {
    .slide-title {
      font-size: 1.9rem;
      line-height: 1em;
      margin-top: 0.5em;
    }
    .slide-subtitle {
      font-size: 1.2rem;
      padding: 0 65px;
      line-height: 1.3em;
    }
    .slide-body {
      font-size: 1rem;
      padding: 0 20px;
      margin-left: 4em;
line-height: 1em;
    }
    .enhanced-btn {
      padding: 12px 24px;
      font-size: 14px;
    }
  }
`;

const ExpandedGlow = styled.div`
  background-color: transparent;
  border-radius: 16px;



  }
  animation: glow 2s infinite;
`;

const RecommendationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px 0px;

  .icon {
    width: 20px;
    height: 20px;
  }
`;

const Recommendation = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  font-size: 1rem;
  width: 100%;
`;

const TextContainer = styled.span`
  flex: 1;
  color: var(--text-color);
  font-size: 1rem;
  line-height: 1.5;
`;

export default WithStore(OnboardingSlider, (state) => ({
  currentSlide: state.currentSlide,
  totalSlides: state.totalSlides,
}));
