import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useSubscription } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { format } from 'date-fns';
import { setAmplitudeUserId, setAmplitudeUserProperties, sendAmplitudeData } from 'src/utils/amplitude';
import { NOTIFICATIONS_QUERY } from 'src/graphql/notifications';
import { useAuth } from 'oidc-react';

const userThing = () => {
  const { userData, userManager } = useAuth();
  const [notifications, setUserNotifications] = useState([]);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [activeFolder, setActiveFolder] = useState(localStorage.getItem('activeFolder') || undefined);
  const [viewListMode, setViewListMode] = useState(Boolean(localStorage.getItem('sizle.viewListMode')));
  const [notificationView, setNotificationView] = useState(Boolean(localStorage.getItem('sizle.notificationView')));
  const [themeMode, setThemeMode] = useState(localStorage.getItem('mode') || 'light');
  const [isCurrentWorkspaceAdmin, setCurrentWorkspaceAdmin] = useState(null);
  const [workspaces, setUserWorkspaces] = useState([]);
  const lastTooltipShown = localStorage.getItem('hideCreateTooltip');
  localStorage.setItem('hideCreateTooltip', format(new Date(), 'yyyy-mm-dd'));
  const [hideCreateTooltip, setHideCreateTooltip] = useState(lastTooltipShown === format(new Date(), 'yyyy-mm-dd'));
  const [refetchPresentations, setRefetchPresentations] = useState(null);
  const [searchPresentations, setSearchPresentations] = useState('');
  const [visiblePresentations, setVisiblePresentations] = useState([]);
  const [_cookies, setCookie, removeCookie] = useCookies();
  const [workspaceIndex, setWorkspaceIndex] = useState(0);
  const [avatarCacheTimestamp, setAvatarCacheTimestamp] = useState(new Date());

  // Ensure light theme is saved to localStorage by default
  useEffect(() => {
    if (!localStorage.getItem('mode')) {
      localStorage.setItem('mode', 'light');
    }
  }, []);

  const updateUser = useCallback(async (details: { firstName?: string; lastName?: string; phoneNumber?: string }, refresh = true) => {
    const res = await axios({
      url: '/api/self',
      data: { details },
      method: 'post',
    });
    await userManager.signinSilent();

    return res.data;
  }, []);

  useEffect(() => {
    if (!userData) {
      return;
    }
    Sentry.setUser({
      email: userData?.profile.email || 'anonymous',
      id: userData?.profile?.email || 'anonymous',
      name: userData?.profile?.name || 'anonymous',
    });
    setAmplitudeUserId(userData?.profile?.email);
    setAmplitudeUserProperties({ accountTier: userData?.profile?.accountTier });
  }, [userData]);

  const pollUserNotificationsResult = useSubscription(NOTIFICATIONS_QUERY, {
    variables: { userId: userData?.profile?.sub },
  });

  useEffect(() => {
    if (pollUserNotificationsResult && pollUserNotificationsResult.data) {
      setUserNotifications(pollUserNotificationsResult.data.notifications);
    }
  }, [pollUserNotificationsResult]);

  const updateUserAvatar = useCallback(async (file) => {
    const formData = new FormData();
    formData.append('avatar', file);
    await axios({
      url: '/api/self/avatar',
      method: 'POST',
      data: formData,
    });
  }, []);

  return {
    idToken: null,
    updateUser,
    user: userData?.profile,
    activeFolder,
    viewListMode,
    notificationView,
    themeMode,
    notifications,
    currentWorkspace,
    workspaces,
    workspaceIndex,
    setWorkspaceIndex,
    isCurrentWorkspaceAdmin,
    setCurrentWorkspace,
    setActiveFolder: useCallback(
      (f) => {
        localStorage.setItem('activeFolder', f);
        setActiveFolder(f);
      },
      [setActiveFolder]
    ),
    setViewListMode,
    setNotificationView,
    setThemeMode,
    setCurrentWorkspaceAdmin,
    setUserWorkspaces,
    getAccessTokenSilently: null,
    refetchPresentations,
    setRefetchPresentations,
    visiblePresentations,
    setVisiblePresentations,
    hideCreateTooltip,
    setHideCreateTooltip,
    searchPresentations,
    setSearchPresentations,
    lastSeenProTrialBanner: null, // user && getUserMetadata(user).lastSeenProTrialBanner,
    updateUserAvatar,
    sendAmplitudeData,
    avatarCacheTimestamp,
    setAvatarCacheTimestamp,
  };
};

export const UserProvider = ({ children }: { children: React.ReactNode[] }) => {
  const value = userThing();

  return <userContext.Provider value={value}>{children}</userContext.Provider>;
};

export const userContext = React.createContext<ReturnType<typeof userThing>>({} as any);
const useUserContext = () => {
  return useContext(userContext);
};

export default useUserContext;
