import React, { useRef, useEffect, useState, useCallback } from 'react'
import { zoomIn, zoomOut } from 'src/design-system/icons'
import SVGInline from 'react-svg-inline'
import styled from '@emotion/styled'
import { useAuth } from 'oidc-react'
import { useNavigate, useParams } from 'react-router-dom'
import { min, addMinutes, differenceInDays, differenceInSeconds } from 'date-fns'
import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import { Document, Page } from 'react-pdf/dist/esm/entry.vite'
import { PinScreen, EmailScreen, useLeadOpenEvents, usePresentationAnalytics } from 'src/modules/player'
import QuickPinchZoom from 'react-quick-pinch-zoom'
import ViewerHeader from './ViewerHeader'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { useUserContext } from 'src/context'
import SizleLoader from 'src/modules/controls/components/SizleLoader'
import { ReactionsWidget } from 'src/modules/reactions'
import { useWindowSize } from 'src/modules/controls/hooks/useWindowSize'

function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

const ViewerPage = () => {
  const navigate = useNavigate()
  const { linkId } = useParams()
  const { user } = useUserContext()
  const { isLoading } = useAuth()
  const [currentLeadId, setCurrentLeadId] = useState<any>(null)
  const { createPublicLead, updateLeadOpenEvents } = useLeadOpenEvents()
  const { presentationStart, viewSlide, presentationEnded } = usePresentationAnalytics()
  const [_, height] = useWindowSize()

  const oldPresentationEnded = usePrevious(presentationEnded)
  const [showingPinScreen, setShowingPinScreen] = useState(false)
  const [showingEmailScreen, setShowingEmailScreen] = useState(false)
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [failedPinCount, setFailedPinCount] = useState(0)
  const [documentLoaded, setDocumentLoaded] = useState(false)
  const [linkDetails, setLinkDetails] = useState<any>(null)
  const [page, setPage] = useState(1)
  const [numPages, setNumPages] = useState(null)
  const [docZoom, setDocZoom] = useState(1)
  const [_downloadEnabled, setDownloadEnabled] = useState(true)
  const [pin, setPin] = useState('')

  const fetchLinkDetails = async () => {
    // Get link details
    const { data: linkDetails } = await axios({
      url: `/api/links/${linkId}`
    })
    setLinkDetails(linkDetails)

    setShowingEmailScreen(linkDetails?.requiresEmailCapture && !user?.email)
    // Determine lead id
    let leadId = linkDetails?.emailLead?.leadUuId || linkDetails?.staticLead?.leadId
    if (!leadId && user?.email) leadId = await createPublicLead(linkId, user.email)
    if (!leadId && !linkDetails.requiresEmailCapture && !user?.email) leadId = await createPublicLead(linkId)
    if (leadId) setCurrentLeadId(leadId)
    // Burn timer and expiration date
    const { burnTimer, expirationDate } = linkDetails
    if (burnTimer || expirationDate) {
      const dates = []
      if (burnTimer) dates.push(addMinutes(linkDetails.earliestActivity ? new Date(linkDetails.earliestActivity) : new Date(), burnTimer))
      if (expirationDate) dates.push(new Date(expirationDate))
      const expireAt = min(dates)
      handleExpiry(expireAt)
    }
  }

  const handleExpiry = (expireAt: Date) => {
    const secondsRemaining = differenceInSeconds(expireAt, new Date())
    if (differenceInDays(expireAt, new Date()) < 20) {
      setTimeout(() => navigate(`/expired-link/${linkId}`, { replace: true }), secondsRemaining * 1000)
    } else {
      setTimeout(() => handleExpiry(expireAt), 1440000)
    }
  }

  // Wait for document loaded, link metadat retrieved and lead created, then post analytics
  useEffect(() => {
    if (currentLeadId && linkDetails && documentLoaded) {
      if (!linkDetails?.downloads_enabled) {
        setDownloadEnabled(false)
      }
      updateLeadOpenEvents({ leadId: currentLeadId })
      presentationStart({
        presentationId: linkDetails.presentation.presentationId,
        leadId: currentLeadId,
        linkId,
        workspaceId: linkDetails.presentation.workspace_id
      })
    }
  }, [currentLeadId, linkDetails, documentLoaded, linkId])

  useEffect(() => {
    console.log('should fetch link details', isLoading)
    if (!isLoading) {
      fetchLinkDetails().catch(e => console.error(e))
    }
  }, [isLoading])

  // Add document closed analytics
  oldPresentationEnded && window.removeEventListener('beforeunload', oldPresentationEnded)
  window.addEventListener('beforeunload', presentationEnded)

  // Try load document with newly entered pin
  const handlePinEntry = async (pin: string) => {
    setPin(pin)
  }

  const onDocumentLoadFailure = async ({ status }: {status: number}) => {
    if (status === 400) {
      toastr.error('Document may not be served from this custom domain.')
    } else if (status === 403 || status === 401) {
      setFailedPinCount(p => p + 1)
      setShowingPinScreen(true)
      setLoadingDocument(false)
      setDocumentLoaded(true)
    } else {
      toastr.error('Unable to load document, please contact support!')
      console.error('Failed to load document')
    }
  }

  function onDocumentLoadSuccess ({ numPages }: {numPages: number}) {
    setNumPages(numPages)
    sendAmplitudeData('VIEWING_DOCUMENT')
    setDocumentLoaded(true)
    setShowingPinScreen(false)
  }

  // Use viewer entered email to create public link
  const handleEmailEntry = async (email: string) => {
    const leadId = await createPublicLead(linkId, email)
    setCurrentLeadId(leadId)
    setShowingEmailScreen(false)
  }
  const handleZoom = useCallback(
    (zoomChange: number) => {
      setDocZoom((prevZoom) => {
        const newZoom = Math.max(0.5, Math.min(prevZoom + zoomChange, 3)); // Restrict zoom between 0.5x and 3x
  
        const viewer = document.querySelector('.viewer-doc');
        if (viewer) {
          const { scrollLeft, scrollTop, clientWidth, clientHeight } = viewer;
  
          // Calculate the center of the viewport relative to the current zoom level
          const centerX = (scrollLeft + clientWidth / 2) / prevZoom;
          const centerY = (scrollTop + clientHeight / 2) / prevZoom;
  
          // Adjust scroll positions based on the new zoom level
          const newScrollLeft = centerX * newZoom - clientWidth / 2;
          const newScrollTop = centerY * newZoom - clientHeight / 2;
  
          // Apply the new scroll positions, ensuring they stay within bounds
          viewer.scrollTo({
            left: Math.max(0, newScrollLeft),
            top: Math.max(0, newScrollTop),
            behavior: 'auto', // Use 'smooth' for smoother transitions if needed
          });
        }
  
        return newZoom;
      });
    },
    []
  );
  
  const handleArrowKeys = useCallback((e) => {
    if (e.key === 'ArrowRight') {
      setPage(p => Math.min((numPages || 99), p + 1))
    } else if (e.key === 'ArrowLeft') {
      setPage(p => Math.max(1, p - 1))
    }
    console.log(e)
  }, [numPages])

  useEffect(() => {
    console.log('adding event listener')
    window.addEventListener('keyup', handleArrowKeys)
    return () => {
      window.removeEventListener('keyup', handleArrowKeys)
    }
  }, [handleArrowKeys])

  useEffect(() => {
    viewSlide(page)
  }, [page])

  // console.log(height)

  return (
    <Wrapper onKeyUp={handleArrowKeys}>
      <Header>
      </Header>
  
      {/* Controls Positioned at Top Center */}
      <div className="page-controls">
  <span className="page-info">
    {page} / {numPages || 1}
  </span>
  <button
    className="btn"
    onClick={() => {
      if (page > 1) {
        const prevPageElement = document.getElementById(`page-${page - 1}`);
        if (prevPageElement) {
          prevPageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          setPage((p) => p - 1);
        }
      }
    }}
    disabled={page === 1}
  >
    Prev
  </button>
  <button
    className="btn"
    onClick={() => {
      if (page < numPages) {
        const nextPageElement = document.getElementById(`page-${page + 1}`);
        if (nextPageElement) {
          nextPageElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          setPage((p) => p + 1);
        }
      }
    }}
    disabled={page === numPages}
  >
    Next
  </button>
  <button className="btn" onClick={() => setDocZoom((zoom) => zoom - 0.2)} title="Zoom Out">
    <SVGInline svg={zoomOut} />
  </button>
  <button className="btn" onClick={() => setDocZoom((zoom) => zoom + 0.2)} title="Zoom In">
    <SVGInline svg={zoomIn} />
  </button>
</div>

      {!documentLoaded && <SizleLoader />}
  
      <ViewerWrapper hidden={!documentLoaded}>
        {linkDetails && (
          <ViewerHeader
            linkId={linkId}
            linkDetails={linkDetails}
            currentLeadId={currentLeadId}
          />
        )}
        <ViewerContainer>
          {document !== null && (
            <div className="viewer-doc">
              <QuickPinchZoom>
                <Document
                  file={`/api/links/${linkId}/file${pin ? `?pin=${pin}` : ''}`}
                  onLoadError={onDocumentLoadFailure}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
{Array.from(new Array(numPages), (_, index) => (
  <PageWrapper key={index} id={`page-${index + 1}`}>
    <Page pageNumber={index + 1} scale={docZoom} />
  </PageWrapper>
))}
                </Document>
              </QuickPinchZoom>
            </div>
          )}
        </ViewerContainer>
        {linkDetails?.reactions_enabled && documentLoaded && (
          <ReactionsWidget leadId={currentLeadId} />
        )}
      </ViewerWrapper>
  
      {showingPinScreen && (
        <PinScreen
          checkPinInput={(p) => handlePinEntry(p)}
          incorrectPinAttempted={failedPinCount}
          loading={loadingDocument}
        />
      )}
      {showingEmailScreen && (
        <EmailScreen linkDetails={linkDetails} onSubmit={handleEmailEntry} />
      )}
    </Wrapper>
  );
  
};

const Wrapper = styled.div`
  height: 100%;
  background: #383838;

  .page-info {
  font-size: 0.875rem;
  color: white;
  margin-right: 16px;
 background-color: rgba(51, 51, 51, 0.5); /* Half-opacity */
  padding: 8px 28px;
  border-radius: 4px;
}

  .viewer-doc {
    overflow: auto;
    height: calc(100vh);
    position: relative;
  }
  
  .page-btns {
    bottom: 20px;
    position: absolute;
}

.viewer-doc {
    overflow: auto;
    height: calc(100vh)
      display: inline-block;;
    position: relative;
  }

  .page-controls {
    position: fixed;
    top: 1em; /* Positioned below the header */
    left: 16%;
    transform: translateX(-50%); /* Horizontally center the controls */
    z-index: 1050; /* Ensure controls are on top */
    display: flex;
      margin-left: 33%;
    gap: 8px;

    .btn {
      background: #000;
      color: white;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background: #4631e9;
      }

      &:disabled {
        background: #555;
        cursor: not-allowed;
      }
    }
  }
`;

const ViewerWrapper = styled.div`
  height: calc(100vh - 60px); /* Account for header height */
  align-items: center; /* Center horizontally */
  padding: 16px 0; /* Add padding around the pages */
`;

const ViewerContainer = styled.div`
  width: 100%;
  flex-direction: column; /* Stack pages vertically */
  align-items: center; /* Center horizontally */
`;

const PageWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  width: 100%;
  background: white;
  scroll-snap-align: start; /* Ensure scrolling aligns pages correctly */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #202020;
  color: white;
  z-index: 102; /* Increased z-index for visibility above content */
  position: sticky; /* Sticks to the top of the viewport */
  top: 0;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  margin: 0;
  flex-grow: 1;
  text-align: center;
`;

const Controls = styled.div`
  display: flex;
  gap: 8px;

  .btn {
    background: #000;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: #4631e9;
    }

    &:disabled {
      background: #555;
      cursor: not-allowed;
    }
  }
`;

const DocumentWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 100px); /* Adjust height to leave space for controls */
  display: inline-block;
  flex-direction: column; /* Ensure vertical layout */
  align-items: center;
`;

export default ViewerPage
