import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { workspaceContext } from 'src/context';

export const ProPopup: React.FunctionComponent<{
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  requiredFeature?: string;
  active?: boolean;
}> = ({
  active = false,
  title = 'Upgrade to unlock',
  subtitle = 'Choose a plan to unlock this feature',
  requiredFeature,
  children,
}) => {
  const { currentWorkspacePlan, workspaceIndex } = useContext(workspaceContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // Log missing requiredFeature or plan issues
  if (!requiredFeature) {
    console.warn('ProPopup: `requiredFeature` is missing or undefined.');
  }
  if (!currentWorkspacePlan) {
    console.warn('ProPopup: `currentWorkspacePlan` is not defined.');
  }

  // Calculate preventAccess
  const preventAccess =
    requiredFeature && currentWorkspacePlan
      ? !currentWorkspacePlan[requiredFeature]
      : active;

  console.log('ProPopup Debug:', {
    requiredFeature,
    active,
    currentWorkspacePlan,
    preventAccess,
  });

  // Handlers
  const closePopup = useCallback(() => setIsOpen(false), []);
  const goToBillingAndClose = useCallback(() => {
    setIsOpen(false);
    navigate(`/s/${workspaceIndex}/billing`);
  }, [navigate, workspaceIndex]);
  const openPopup = useCallback(() => {
    if (preventAccess) {
      setIsOpen(true);
    }
  }, [preventAccess]);

  return (
    <div className='propopup'>
      {children}
      {isOpen && (
        <div
          className='modal-container'
          onClick={closePopup}
          role='dialog'
          aria-labelledby='propopup-title'
          aria-describedby='propopup-subtitle'
        >
          <div onClick={(e) => e.stopPropagation()} className='modal'>
            <h1 id='propopup-title'>{title}</h1>
            <h2 id='propopup-subtitle'>{subtitle}</h2>
            <div className='propopup-buttons'>
              <button
                className='btn primary'
                onClick={goToBillingAndClose}
                aria-label='View plans'
              >
                View plans
              </button>
              <button
                onClick={closePopup}
                className='btn secondary'
                aria-label='Maybe later'
              >
                Maybe later
              </button>
            </div>
          </div>
        </div>
      )}
      {preventAccess && !isOpen && (
        <button
          className='anchor'
          onClick={openPopup}
          aria-label='Learn more about unlocking this feature'
        >
          Learn more
        </button>
      )}
    </div>
  );
};
