import React, { useState, useContext } from 'react'
import PinInput from './PinInput'
import ExpirationDateInput from './ExpirationDateInput'
import BurnTimerInput from './BurnTimerInput'
import styled from '@emotion/styled'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toastr } from 'react-redux-toastr'
import { Link, useNavigate } from 'react-router-dom'
import { ProPopup } from 'src/modules/modals/components/ProPopup'
import { FiClipboard } from 'react-icons/fi'
import { useAnalytics } from 'use-analytics'
import { useTranslation } from 'react-i18next'
import { workspaceContext } from 'src/context'
import { usePublicLink } from 'src/modules/presentations'
import gotoCharts from '@sizle-icons/goto-charts.svg';
import LabeledToggle from 'src/modules/presentations/components/popup/LabeledToggle'

const CreatePublicLinkTab = ({ presentationId, closeModal, requiredFeature }) => {
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const [{ url, linkId }, createPublicLink] = usePublicLink();
  const { workspaceIndex } = useContext(workspaceContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [burnTimer, setBurnTimer] = useState(null);
  const [commentsEnabled, setCommentsEnabled] = useState(true);
  const [downloadsEnabled, setDownloadsEnabled] = useState(true);
  const [reactionsEnabled, setReactionsEnabled] = useState(true);
  const [requiresEmailCapture, setRequiresEmailCapture] = useState(false);
  const [displayingLinkCreated, toggleDisplayCreatedLink] = useState(false);
  const { currentWorkspacePlan } = useContext(workspaceContext);
  const userFeatures = currentWorkspacePlan || {};

  // Validate requiredFeature
  if (!requiredFeature) {
    console.warn('ProPopup: requiredFeature is missing or undefined.');
  }

  const preventAccess =
    requiredFeature && !(currentWorkspacePlan?.[requiredFeature] ?? false);

  const clickCreateButton = async () => {
    setLoading(true);

    try {
      await createPublicLink(presentationId, {
        requiresEmailCapture,
        pin,
        burnTimer,
        expirationDate,
        downloadsEnabled,
        commentsEnabled,
        reactionsEnabled,
      });

      toggleDisplayCreatedLink(true);
      sendAmplitudeData('SHARED_DOCUMENT_VIA_PUBLIC_LINK');
      track('link-created', { type: 'public', hasPin: false });
    } catch (err) {
      console.error(err);
      toastr.error('Error creating link. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const showCopiedMessage = () => {
    toastr.info('Link copied to clipboard', { timeOut: 1000 });
  };

  const mockUserFeatures = {
    allow_downloads: true,
    // Other features...
  };
  

  const clickCreateAnotherLink = () => {
    toggleDisplayCreatedLink(false)
  }

  const handleDownloadToggle = (isChecked) => {
    console.log('userFeatures:', userFeatures);
    console.log('userFeatures.allow_downloads:', userFeatures?.allow_downloads);
  
    if (!userFeatures?.allow_downloads && isChecked) {
      toastr.error(
        'Upgrade Required',
        'This feature is only available in Solo plans and above.'
      );
      return;
    }
    setDownloadsEnabled(isChecked);
  };
  

  const handleSelectRecipient = (selectedRecipient) => {
    toggleDisplayCreatedLink(false)
    setSelectedRecipient(selectedRecipient.value)
  }

  const handleSelectOrganisation = (selectedOrg) => {
    toggleDisplayCreatedLink(false)
    setSelectedOrganisation(selectedOrg.value)
    setSelectedRecipient(null)
  }

  const handlePinChange = (pin) => setPin(pin)
  const handleBurnTimerChange = (timer) => setBurnTimer(timer)
  const handleExpirationDateChange = (date) => setExpirationDate(date)

  return !displayingLinkCreated
    ? (
      <Container>
        <div className='p2'>
        <StyledBoxOuter>
            <h4>
              <br></br>
            <strong>Lead capture</strong>
            <Badge>Pro feature</Badge>
          </h4>
          <p>Collect viewer emails to grow leads automatically.</p>
          <ProPopup
  title="Feature discovered"
  subtitle="Email lead collection is included in Pro plans and above."
  requiredFeature="email_collection"
>
  <LabeledToggle
    title="Enable Email Capture"
    defaultChecked={requiresEmailCapture}
    onChange={(e) => setRequiresEmailCapture(e.target.checked)}
  />
</ProPopup>
            <br />
          <h4><strong>Engagement</strong></h4>
          <p>Encourage engagement and capture feedback.</p>
            <LabeledToggle title='Viewer Reactions' subtitle='' defaultChecked={reactionsEnabled} onChange={e => setReactionsEnabled(e.target.checked)} />
            <LabeledToggle title='Viewer Comments' subtitle='' defaultChecked={commentsEnabled} onChange={e => setCommentsEnabled(e.target.checked)} />
              <br></br>
              <h4><strong>Security</strong></h4>
<p>Control access with timers and restrictions.</p>
<LabeledToggle title='Allow Download' subtitle='' defaultChecked={downloadsEnabled} onChange={e => setDownloadsEnabled(e.target.checked)} />
<PinInput handleAccessPinChange={handlePinChange} />

<ProPopup
  requiredFeature="share_timers"
  title="Feature discovered"
  subtitle="Timers are included in Pro plans and above."
>
  <div style={{ display: 'flex', alignItems: 'center'}}>
    <ExpirationDateInput
      onChange={handleExpirationDateChange}
      label={t('Expiry')}
    />
    <Badge>Pro feature</Badge>
  </div>
  <div style={{ display: 'flex', alignItems: 'center'}}>
    <BurnTimerInput
      onChange={handleBurnTimerChange}
      label={t('Burn After')}
    />
    <Badge>Pro feature</Badge>
  </div>
</ProPopup>
          </StyledBoxOuter>
        </div>
        <br />
        <LeftAlignedButton>
        <ProPopup requiredFeature="sharing">
        <PrimaryButton className="btn" loading={loading ? 'loading' : undefined} onClick={clickCreateButton}>
  {t('Create & copy link')}
</PrimaryButton>
</ProPopup>
        </LeftAlignedButton>
      </Container>
    )
    : (
      <>
        <div className='p2'>
          <SuccessMessage>
            <h5>{t('A shareable link has been created.')} <span role='img' aria-label='Success!'></span></h5>
          </SuccessMessage>
        </div>
        <LinkContainer>
  <CopyToClipboard text={`${url}`} onCopy={() => showCopiedMessage()}>
    <LinkInputContainer>
      <LinkInput disabled value={url} onClick={() => showCopiedMessage()}/>
      <CopyIconContainer>
        <FiClipboard />
      </CopyIconContainer>
    </LinkInputContainer>
  </CopyToClipboard>
  <LinkButtons>
    <CopyToClipboard text={`${url}`} onCopy={() => showCopiedMessage()}>
      <CopyButton>
        <FiClipboard /> {t('Copy link')}
      </CopyButton>
    </CopyToClipboard>

    <AnalyticsButton onClick={() => {
        closeModal(); // Close the modal before navigating
        navigate(`/s/${workspaceIndex}/presentation/${presentationId}/analytics?filter=public&link_id=${linkId}`);
      }}
    >
  <span className="icon" />
  Leads
</AnalyticsButton>
  </LinkButtons>
</LinkContainer>
      </>
    )
}

const AnalyticsButton = styled.button`
display: flex;
align-items: center;
gap: 8px; /* Space between icon and text */
background: var(--background-color);
color: var(--text-color);
border: 1px solid var(--border-color);
border-radius: 6px;
padding: 5px 13px;
font-size: 0.95rem;
font-weight: 500;
justify-content: center;
position: relative;
transition: background 0.2s, color 0.2s;
cursor: pointer;

&:hover {
  background: var(--background-alt-color);
  color: var(--text-color);
}

&:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--primary-color-vfaint);
}

.icon {
 width: 16px;
  height: 16px;
  background-color: var(--text-color); /* Match text color */
  -webkit-mask: url(${gotoCharts}) no-repeat center;
  mask: url(${gotoCharts}) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
}
`;

const BorderInput = styled.input`
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  background-color: var(--input-background);
  color: var(--input-color);
  font-size: 12px;
  padding: 8px 12px;
  display: block;
  margin: 0 12px 10px;
  width: 250px; /* Shortened the input field */
  min-height: 30px;
  cursor: pointer;
`

const Badge = styled.span`
  background: #e5d1ff;
  color: #6200ee;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: 600;
  margin-left: 1em;
`;

const Container = styled.div`
  padding: 0px 0px 0 0px;
`

const StyledBoxOuter = styled.div`
  display: block;
  position: relative;
  width: 100%;
  color: var(--text-color);
  background-color: var(--background-alt-color);

  .email-capture {
    height: 60px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    &>:first-child {
      flex-basis: 70%;
    }
    .switch-wrapper {
      position: relative;
      float: right;
      bottom: 8px;
    }
  }
`

const SuccessMessage = styled(Container)`
  text-align: left;
  margin: 0 auto;

  h6 {
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 4px;
    color: var(--text-color);
  }
  p {
    margin: 0 10px 16px;
    color: var(--text-color);
  }
  i {
    color: var(--text-color);
    font-style: normal;
    font-weight: 500;
  }
  > span {
    color: var(--text-color);
    font-size: 12px;
    line-height: 1.4;
    display: block;
  }
`

const LinkContainer = styled.div`
  width: 100%;
  text-align: left;
  padding: 16px 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: left;

  h6 {
    color: var(--text-color);
    font-size: 12px;
    display: block;
  }
`

const LinkButtons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  >button:first-of-type {
    margin-right: 20px;
  }
`

const LinkInputContainer = styled.div`
  display: flex;
  max-width: 80%;
  align-items: center;
  position: relative;
  overflow: hidden; /* Ensure content stays within the container */
`;

const LinkInput = styled(BorderInput)`
  font-size: 1em;
  margin: 5px 0 25px;
  flex-grow: 1;
  position: relative;
  z-index: 1; /* Ensure the text remains above the gradient */
  
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px; /* Adjust the width of the fade */
    background: linear-gradient(to left, white, transparent);
    pointer-events: none; /* Ensure the gradient doesn't interfere with interactions */
  }
`;

const CopyIconContainer = styled.div`
  position: relative;
  cursor: pointer;
  color: var(--text-color);
  font-size: 1.4em;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 25px;
  z-index: 2; /* Position the icon above the gradient */
`;


const LeftAlignedButton = styled.div`
  text-align: left;
`

const PrimaryButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.1em;
  font-weight: 550;
  color: white;
  background-color: var(--primary-color);
  border-radius: 6px; 
  padding: 15px 22px;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--primary-color-shade);
  }

  svg {
    margin-right: 8px;
    font-size: 1.2em;
  }

  &:active {
    transform: scale(0.98);
  }
`

const CopyButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 1em;
  color: white;
  background-color: var(--primary-color);
  border-radius: 6px; 
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--primary-color-shade);
  }

  svg {
    margin-right: 8px;
    font-size: 1.2em;
  }

  &:active {
    transform: scale(0.98);
  }
`

export default CreatePublicLinkTab
