import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { FiUpload } from 'react-icons/fi';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';

const validTypes = ['application/pdf'];

const Dropzone = ({ element, submit, cancel, isDark = false, result, children, className }) => {
  const [isDragging, setDragging] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (element) {
      element.addEventListener('dragenter', handleDragEnter);
      element.addEventListener('dragover', handleDragOver);
      element.addEventListener('dragleave', handleDragLeave);
      element.addEventListener('drop', handleDrop);

      return () => {
        element.removeEventListener('dragenter', handleDragEnter);
        element.removeEventListener('dragover', handleDragOver);
        element.removeEventListener('dragleave', handleDragLeave);
        element.removeEventListener('drop', handleDrop);
      };
    }
  }, [element]);

  useEffect(() => {
    if (result) finishUpload();
  }, [result]);

  const cancelUpload = useCallback((e) => {
    if ((isDragging || isLoading) && !!cancel) {
      e.preventDefault();
      setDragging(false);
      setLoading(false);
      cancel();
    }
  }, [isDragging, isLoading, cancel]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') cancelUpload(e);
  }, [cancelUpload]);

  useEffect(() => {
    if (cancel) {
      window.addEventListener('click', cancelUpload);
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('click', cancelUpload);
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [cancel, cancelUpload, handleKeyDown]);

  const finishUpload = () => {
    setDragging(false);
    setLoading(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    if (!isDragging) setDragging(true);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setDragging(false);
    const files = e.dataTransfer.files;

    if (files.length) {
      if (validTypes.includes(files[0].type)) {
        setLoading(true);

        try {
          await submit(files);
        } catch (error) {
          console.error(error);
          toastr.error(t('toastr.Error uploading file'));
        } finally {
          finishUpload();
        }
      } else {
        toastr.error(t('toastr.This file type is not supported'));
        finishUpload();
      }
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  return (
    <Wrapper className={className} onClick={cancelUpload}>
      <DropOverlay isDragging={isDragging}>
        <DropContent>
          <Icon />
          <DropMessage>{t('Drop your PDF file here to upload')}</DropMessage>
        </DropContent>
      </DropOverlay>
      <ChildrenContainer
        isDragging={isDragging}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        isLoading={isLoading}
      >
        {children}
      </ChildrenContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const DropOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, ${(props) => (props.isDragging ? 0.6 : 0)});
  display: ${(props) => (props.isDragging ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none;
  transition: background-color 0.3s ease, opacity 0.3s ease;
`;

const DropContent = styled.div`
  text-align: center;
  color: #fff;
  font-size: 20px;
  border: 3px dashed #fff;
  padding: 24px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isDragging ? 'scale(1.05)' : 'scale(1)')};
`;

const Icon = styled(FiUpload)`
  font-size: 40px;
  margin-bottom: 12px;
`;

const DropMessage = styled.div`
  font-size: 18px;
`;

const ChildrenContainer = styled.div`
  opacity: ${(props) => (props.isDragging ? 0.3 : 1)};
  transition: opacity 0.3s ease;
`;

export default Dropzone;
