import React, { useContext } from 'react'
import { ModeContext } from 'src/context'
import animationData from 'src/design-system/animations/sizle.json'
import Lottie from 'react-lottie-player'

const SizleLoader = ({ children, ...props }) => {
  const { hideSizleBrand } = useContext(ModeContext)
  return (
    <div className='sizle-loader'>
      <div className='loader-inner'>
        <Lottie
          animationData={animationData}
          loop
          play
          speed={1.8}
          style={{ width: 100, height: 100 }}
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
        />
      </div>
      <span className='slogan'>{children || (!hideSizleBrand && <>Content loading, <b>one moment</b></>)}</span>
    </div>
  )
}
export const SizleLoaderContainer = ({ children }) => (
  <div className='sizle-loader-outer'><SizleLoader>{children}</SizleLoader></div>)

export default SizleLoader
