import React, { useContext, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Pane, CheckoutForm } from 'src/modules/billing';
import { subscriptionContext } from 'src/context';

const ELEMENTS_OPTIONS = { fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }] };
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const SubscriptionForm = ({ onComplete, cancel, plan }) => {
  const { t } = useTranslation();
  const { currentPlan, paymentMethod, purchaseSubscription } = useContext(subscriptionContext);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState(false); // Tracks form-level errors

  const validateFields = () => {
    // Simulate validation of all form fields. For Stripe, this would involve checking the card element.
    const hasErrors = document.querySelectorAll('.error-field').length > 0;
    setFormErrors(hasErrors);
    return !hasErrors;
  };

  const submitSubscription = async ({ token, promo }) => {
    if (!validateFields()) {
      toastr.error(t('Please correct the errors in the form before proceeding.'));
      return;
    }

    if (!token) {
      setError(true);
      toastr.error(t('Please add a valid payment method.'));
      return;
    }

    setSubmitting(true);
    setError(false);
    try {
      const message = await purchaseSubscription(token, promo, plan.id);
      toastr.info(t(message));
      setSubmitting(false);
      onComplete && onComplete();
    } catch (e) {
      setSubmitting(false);
      setError(true);
      toastr.error(t((e as any)?.message || 'Payment failed.'));
    }
  };

  const submitUpgrade = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!validateFields()) {
      toastr.error(t('Please correct the errors in the form before proceeding.'));
      return;
    }

    setSubmitting(true);
    setError(false);
    try {
      const promoCode = (e.currentTarget.elements.namedItem('promo') as HTMLInputElement)?.value;
      const message = await purchaseSubscription(null, promoCode, plan.id);
      toastr.info(t(message));
      setSubmitting(false);
      onComplete && onComplete();
    } catch (e) {
      setSubmitting(false);
      setError(true);
      toastr.error(t((e as any)?.message || 'Payment failed.'));
    }
  };

  return (
    <div className='subscription-form'>
      <style>{`
        .error-field {
          border: 2px solid red;
          animation: shake 0.3s;
        }
        .error-message {
          color: red;
          font-size: 0.9em;
          margin-top: 5px;
        }
        @keyframes shake {
          0%, 100% { transform: translateX(0); }
          25%, 75% { transform: translateX(-5px); }
          50% { transform: translateX(5px); }
        }
      `}</style>

      <div>
        <label className='label'>
          <h2>{t('Unlock premium features today')}</h2>
        </label>
        <br />
        <h5>
          {t('Take your experience to the next level with more tools, customisation and functionality tailored to help you grow.')}<br></br>
          <br></br>
          <label className='label'><strong>{t('Secure payments powered by Stripe.')}</strong> </label>
        </h5>
      </div>

      {paymentMethod ? (
        <form onSubmit={submitUpgrade}>
          <div className='submit-buttons'>
            <button
              type='submit'
              className={`btn wide extra-big primary ${submitting && 'loading-spinner'}`}
              disabled={!paymentMethod || submitting}
            >
              {submitting ? t('Processing...') : t('Proceed')}
            </button>
            <button type='reset' onClick={cancel} className='btn secondary'>
              {t('Cancel')}
            </button>
            <br />
          </div>
        </form>
      ) : (
        
        <>
          <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <CheckoutForm
              onSubmit={submitSubscription}
              includePromoField
              actionText={t('Update my plan')}
              showError={error} // Pass error state to the CheckoutForm
            />
            {formErrors && (
              <div className="error-message">
                {t('Please correct the errors in the highlighted fields before submitting.')}
              </div>
            )}
          </Elements>
          <label className='label'>
          <p>
            <br />
            <p>
            {t('Your ')}
            <span className='label'>{t(`${plan?.planTier} ${plan?.billingCadence}`)}</span>
            {t(' plan will cost only ')}
            <span className='label'>${plan?.cost / 100}</span>
            {t(` per ${plan?.billingCadence === 'monthly' ? 'month' : 'year'}.`)}
            </p>
          </p>
        </label>
        </>
      )}
    </div>
  );
};

export default SubscriptionForm;
