import React, { useCallback, useState, useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import { Helmet } from 'react-helmet'
import SVGInline from 'react-svg-inline'

import { sendAmplitudeData } from 'src/utils/amplitude'
import { comment, download } from 'src/design-system/icons'
import { HelpButton } from 'src/design-system/elements'
import { ResponseListPreview } from 'src/modules/responses'
import { PinScreen, EmailScreen, MobileMenu, PoweredByLabel } from 'src/modules/player'
import { workspaceContext } from 'src/context'

const PreviewHeader = ({ stopPreviewing, presentation }) => {
  const [showingPinScreen, showPinScreen] = useState(null)
  const [showingEmailScreen, showEmailScreen] = useState(null)
  const { workspace } = useContext(workspaceContext)

  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      // @TODO: can this be fixed with useLocation()?
      // the target selector doesn't work in react until a page is loaded,
      // so this is needed to open the comment box after redirecting from login
      // as clean as open it on pressing a comment button
      window.location.hash = ''
      window.location.hash = hash
    }
  }, [])

  const handleDownloadClick = useCallback(async () => {
    if (!presentation?.presentationId) return
    window.location = `/api/documents/${presentation?.presentationId}/file`
  }, [presentation?.presentationId])

  const handleCommentClick = useCallback(async () => {
    if (window.location.hash === '#comment-box') window.location.hash = ''
    else window.location.hash = '#comment-box'
  }, [])

  const handleResponseOverlayClick = () => {
    window.location.hash = ''
  }

  const options = [
    {
      children: 'Show PIN page',
      onClick: () => showPinScreen(true)
    },
    {
      children: 'Show email page',
      onClick: () => showEmailScreen(true)
    }
  ]

  return (
    <div className='viewer-header'>
      <Helmet>
        {presentation?.title !== null && <title>{`Sizle - ${presentation?.title}`}</title>}
      </Helmet>
      <div className='left desktop'>
        <PoweredByLabel previewWorkspaceId={workspace?.workspace_id} />
        <button className='btn tertiary' onClick={() => showPinScreen(true)}>Preview PIN</button>
        <button className='btn tertiary' onClick={() => showEmailScreen(true)}>Preview email capture</button>
      </div>
      <div className='left mobile'>
        <PoweredByLabel previewWorkspaceId={workspace?.workspace_id} />
        <MobileMenu displayWidth='1010px' options={options} />
      </div>
      <div className='center'>
        <button className='btn tertiary' onClick={handleCommentClick}>Comment</button>
        {!presentation?.settings?.readOnly && (<button className='btn tertiary' onClick={handleDownloadClick}>Download</button>)}
        <ResponseContainer id='comment-box'>
          <ResponseOverlay onClick={handleResponseOverlayClick} />
          {presentation && <ResponseListPreview presentationId={presentation.presentationId} />}
        </ResponseContainer>
      </div>
      <div className='right desktop'>
        <label className='label big' htmlFor=''>{presentation?.title}</label>
        <HelpButton />
        <button className='btn tertiary' onClick={stopPreviewing}>Close</button>
      </div>
      <div className='right mobile'>
        <HelpButton />
        <button className='btn tertiary' onClick={stopPreviewing}>Close</button>
      </div>
      {showingPinScreen && <PinScreen checkPinInput={() => showPinScreen(false)} previewMode />}
      {showingEmailScreen && <EmailScreen onSubmit={() => showEmailScreen(false)} isLoading={false} previewMode />}
    </div>
)
}

const ResponseContainer = styled.div`
  display: none;
  position: absolute;
  top: 46px;
  z-index: 10;

  &:target {
    display: flex;
  }
`

const ResponseOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 40px;
  z-index: 10;
  width: 10000px;
  height: 100000px;
  opacity: 0.6;
`

PreviewHeader.propTypes = {
  presentation: PropTypes.object
}

export default PreviewHeader
