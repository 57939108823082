import React, { useState, useCallback, useContext, useEffect } from 'react'
import { ProPopup } from 'src/modules/modals/components/ProPopup'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { VscOpenPreview } from 'react-icons/vsc'
import { IoIosArrowBack } from 'react-icons/io'

import { sendAmplitudeData } from 'src/utils/amplitude'
import { info, error, success } from 'src/utils/toastr'
import { useModal } from 'src/modules/modals'
import { presentationContext, workspaceContext } from 'src/context'
import { MobileMenu } from 'src/modules/player'
import HelpButton from 'src/design-system/elements/buttons/HelpButton'

const EditorHeader = ({ PDFTronInstance, previewDocument }) => {
  const { t } = useTranslation()
  const {
    presentation,
    updatePresentationFile,
    presentationTitle,
    savePresentationTitle
  } = useContext(presentationContext)
  const { hasWorkspacePermission } = useContext(workspaceContext)
  const [titleField, setTitleField] = useState('')
  const { setModal } = useModal()
  const { hash } = useLocation()

  const openShareModal = useCallback(() => {
    if (presentation) {
      setModal('share_presentation', {
        title: presentationTitle,
        presentationId: presentation.presentationId,
        openTrackingEnabled: presentation.openTrackingEnabled,
        emailNotificationsEnabled: presentation.emailNotificationsEnabled,
        formEnabled: presentation.formEnabled,
        url: presentation.url
      })
    }
  }, [presentation, presentationTitle, setModal])

  const handleSavePresentationClick = async () => {
    const { docViewer, annotManager } = PDFTronInstance

    const xfdfString = await annotManager.exportAnnotations()
    const doc = docViewer.getDocument()
    const data = await doc.getFileData({ xfdfString, downloadType: 'pdf' })
    const arr = new Uint8Array(data)
    let filename = presentationTitle
    if (filename.split('.').length === 1) {
      filename = `${filename}.pdf`
    } else {
      filename = `${filename.split('.').slice(0, -1).join('.')}.pdf`
    }
    const file = new File([arr], filename, { type: 'application/pdf' })
    try {
      await updatePresentationFile(presentation.presentationId, file)
      success('Document saved.')
    } catch (e) {
      console.error('Failed saving document', e)
      error('Unable to save document. Please contact support!')
    }
  }

  const updateTitle = async () => {
    info('Saving title..')
    await savePresentationTitle(titleField)
    info('Title saved.')
    sendAmplitudeData('DOCUMENT_TITLE_UPDATED')
  }

  useEffect(() => {
    if (presentationTitle) {
      setTitleField(presentationTitle)
    }
  }, [presentationTitle])
  useEffect(() => {
    if (hash === '#share') {
      openShareModal()
    }
  }, [hash, openShareModal])

  const options = [
    {
      children: 'Viewer preview',
      onClick: presentation ? previewDocument : () => { return null }
    },
    {
      children: t('Save'),
      onClick: handleSavePresentationClick
    }
  ]

  return (
    <div className='viewer-header'>
      <Helmet>
        presentation?.title !== null &&<title>{`Sizle - ${presentation?.title}`}</title>
      </Helmet>
      <div className='left desktop'>
        <Link to='../../../presentations'> <button className='btn tertiary'>Back to home</button> </Link>
      </div>
      <div className='left mobile'>
        <Link to='../../../presentations'> <button className='btn tertiaryy'><IoIosArrowBack size={12} /></button> </Link>
      </div>
      <div className='center'>
        <input
          className='title-input'
          onChange={(e) => setTitleField(e.target.value)}
          onBlur={updateTitle}
          value={titleField}
          onKeyPress={(e) => { if (e.charCode === 13) { e.target.blur() } }}
        />
      </div>
      <div className='right desktop'>
        <div className='share-wrap'>
          <ProPopup requiredFeature='sharing'>
            <button className='btn primary' hidden={!hasWorkspacePermission('Share Documents')} onClick={openShareModal}>Share</button>
          </ProPopup>
        </div>
      </div>
      <div className='right mobile'>
        <button className='btn primary' hidden={!hasWorkspacePermission('Share Documents')} onClick={openShareModal}>Share</button>
        <MobileMenu displayWidth='1010px' options={options} />
      </div>
    </div>
  )
}

EditorHeader.propTypes = {
  PDFTronInstance: PropTypes.object
}

export default EditorHeader
