import React from 'react'
import styled from '@emotion/styled'
import SVGInline from 'react-svg-inline'
import { copyWhite, trash, edit } from '@sizle-icons'
import { useMutation } from '@apollo/client'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

import { GET_FOLDERS_QUERY, DELETE_QUERY, DUPLICATE_QUERY } from 'src/graphql/folders'
import useUserContext from 'src/context/UserContext'

const FolderDeleteLink = ({ folderId, label, triggerCloseMenu, ...props }) => {
  const { t } = useTranslation()
  const { currentWorkspace } = useUserContext()

  const [deleteSelectedFolder, deleteResult] = useMutation(DELETE_QUERY, {
    refetchQueries: [{
      query: GET_FOLDERS_QUERY, variables: { workspaceId: currentWorkspace }
    }]
  })

  if (deleteResult.error) {
    toastr.error(t('Error removing folder'))
    triggerCloseMenu()
  } else if (deleteResult.data && deleteResult.data.update_folders) {
    toastr.info(t('Folder {{label}} removed', { label }))
    triggerCloseMenu()
  }

  const onClickDeleteFolder = (e) => {
    if (window.confirm(`Are you sure you want to delete the presentation folder '${label}'?`)) {
      deleteSelectedFolder({ variables: { folderId: `${folderId}` } })
    } else {
      triggerCloseMenu()
    }
  }

  return (
    <li onClick={e => onClickDeleteFolder()}>
      <span>{t('Delete')}</span>
    </li>
  )
}

const FolderDuplicateLink = ({ folderId, label, icon, parentId, triggerCloseMenu, ...props }) => {
  const { t } = useTranslation()
  const { currentWorkspace } = useUserContext()

  const [duplicateSelectedFolder, duplicateResult] = useMutation(DUPLICATE_QUERY, {
    refetchQueries: [{
      query: GET_FOLDERS_QUERY, variables: { workspaceId: currentWorkspace }
    }]
  })

  if (duplicateResult.error) {
    toastr.error('Error duplicating folder.')
    triggerCloseMenu()
  } else if (duplicateResult.data && duplicateResult.data.insert_folders) {
    toastr.info(`Folder '${label}' has been duplicated.`)
    triggerCloseMenu()
  }

  const onClickDuplicateFolder = () => {
    duplicateSelectedFolder({
      variables: {
        folderName: label,
        icon,
        parentId: parseInt(parentId),
        workspaceId: currentWorkspace
      }
    })
  }

  return (
    <li onClick={e => onClickDuplicateFolder()}>
     
    </li>
  )
}

const FolderRenameLink = ({ folderId, label, triggerCloseMenu, triggerRenamePopover, ...props }) => {
  const { t } = useTranslation()
  const onClickRenameFolder = (e) => {
    triggerRenamePopover(true)
    triggerCloseMenu(true)
  }

  return (
    <li onClick={e => onClickRenameFolder()}>
      <span>{t('Rename')}</span>
    </li>
  )
}

const FolderSubMenu = ({
  folderId,
  label,
  isRemovable,
  triggerCloseMenu,
  triggerRenamePopover,
  parentId,
  ...props
}) => {
  return (
    <Wrapper>
      <ul>
        <FolderDuplicateLink folderId={folderId} parentId={parentId} label={label} triggerCloseMenu={triggerCloseMenu} />
        <FolderRenameLink
          folderId={folderId}
          label={label}
          triggerCloseMenu={triggerCloseMenu}
          triggerRenamePopover={triggerRenamePopover}
        />
        {isRemovable && (
          <FolderDeleteLink folderId={folderId} label={label} triggerCloseMenu={triggerCloseMenu} />
        )}
      </ul>
    </Wrapper>
  )
}

const Icon = styled(SVGInline)`
  margin-right: 8px;
  svg {
    polyline,
    line,
    path,
    g {
      stroke: var(--text-color-light);
    }
  }
`

const Wrapper = styled.div`
  li {
    display: flex;
    padding: 0px 0px 2px 10px;
    background: transparent;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    color: var(--text-color);
    &:hover {
      background: var(--input-background-hover);
    }
  }
`

export default FolderSubMenu
