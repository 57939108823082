import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { comment, download } from 'src/design-system/icons';
import HelpButton from 'src/design-system/elements/buttons/HelpButton';
import { ResponseList } from 'src/modules/responses';
import { PoweredByLabel } from 'src/modules/player';

const ViewerHeader = ({ linkDetails, currentLeadId, linkId, pin }: any) => {
  useEffect(() => {
    // Retain hash navigation
    const hash = window.location.hash;
    if (hash) {
      window.location.hash = '';
      window.location.hash = hash;
    }
  }, []);

  const handleDownloadClick = useCallback(() => {
    window.location.href = `/api/links/${linkId}/file${pin ? `?pin=${pin}` : ''}`;
  }, [pin, linkId]);

  const handleCommentClick = useCallback(() => {
    window.location.hash = window.location.hash === '#comment-box' ? '' : '#comment-box';
  }, []);

  const handleResponseOverlayClick = useCallback(() => {
    window.location.hash = '';
  }, []);

  return (
    <Header>
      <Helmet>
        <title>{linkDetails?.presentation?.title || 'Loading document...'}</title>
        <link rel="shortcut icon" href={`/api/links/${linkId}/favicon`} />
      </Helmet>
      <LeftSection>
        <PoweredByLabel linkId={linkId} />
      </LeftSection>
      <CenterSection>
        {linkDetails?.comments_enabled && (
          <Button onClick={handleCommentClick} aria-label="Leave a comment">
            Comment
          </Button>
        )}
        {linkDetails?.downloads_enabled && (
          <Button onClick={handleDownloadClick} aria-label="Download document">
            Download
          </Button>
        )}
        <ResponseContainer id="comment-box">
          <ResponseOverlay onClick={handleResponseOverlayClick} />
          <ResponseList linkDetails={linkDetails} currentLeadId={currentLeadId} />
        </ResponseContainer>
      </CenterSection>
      <RightSection>

      </RightSection>
    </Header>
  );
};

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  background: #000000;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  z-index: 1010;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const CenterSection = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 80%;
  justify-content: center;
`;

const Button = styled.button`
  background: #000;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #4631e9;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const ResponseContainer = styled.div`
  position: absolute;
  top: 60px;
  z-index: 1020;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;

  &:target {
    opacity: 1;
    pointer-events: all;
  }
`;

const ResponseOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

export default ViewerHeader;
