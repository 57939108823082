import React, { useEffect, useState, useContext } from 'react';
import SVGInline from 'react-svg-inline';
import axios from 'axios';
import { useAnalytics } from 'use-analytics';
import { lockBlack, lightMode } from '@sizle-icons';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { useUserContext } from 'src/context';
import { ModeContext } from 'src/context/ModeContext';
import { sendAmplitudeData } from 'src/utils/amplitude';
import { MainProfileForm } from 'src/modules/account';
import SizleLoader from 'src/modules/controls/components/SizleLoader';

const AccountPage = () => {
  const { user } = useUserContext();
  const { mode, setMode } = useContext(ModeContext);
  const { page } = useAnalytics();
  const { t } = useTranslation();

  const [userDetails, setUserDetails] = useState(null);
  const [isLoadingProfile, toggleLoadingProfile] = useState(false);
  const [isChangingPassword, setChangingPassword] = useState(false);

  const appMetadata = user?.['https://sizle.io/app_metadata'] || {};
  const isSocialAccount = userDetails?.identities?.[0]?.isSocial;

  // Events
  const onThemeChange = () =>
    setMode(mode === 'light' ? 'dark' : 'light');

  const onChangePassword = async () => {
    setChangingPassword(true);
    const result = await axios.post('/api/self/resetPassword', {}, { withCredentials: true });

    if (result) {
      toastr.info(t('Password update initiated, please check your email inbox.'));
      sendAmplitudeData('CHANGE_USER_PASSWORD_REQUESTED');
    }
    setChangingPassword(false);
  };

  // Effects
  useEffect(() => {
    page();
    sendAmplitudeData('PAGE_VIEW_ACCOUNT');
  }, []); //eslint-disable-line

  // TODO: refactor it
  useEffect(() => {
    const fetchUserDetails = async () => {
      const result = await axios.get('/api/self');

      if (result) {
        setUserDetails(result.data);
        toggleLoadingProfile(false);
      }
    };

    if (!userDetails) {
      toggleLoadingProfile(true);
      fetchUserDetails();
    }
  }, []); //eslint-disable-line

  return (
    <div id='account-page'>
      {isLoadingProfile && <SizleLoader />}
      {!isLoadingProfile && userDetails && (
        <>
          <MainProfileForm user={userDetails} />
          {!isSocialAccount && (
            <section className='account-sections'>
              <div className='account-section'>
              <h5>Theme</h5>
                <div className='content'>
                  <br></br>
                  <button className={`switch primary ${mode === 'dark' ? 'on' : 'off'}`} onClick={onThemeChange} />
                  <label>Toggle light and dark mode</label>
                </div>
              </div>
              <div className='account-section'>
                <h5>{t('Account security')}</h5>
                <br></br>
                <div className='content'>
                  <button className='btn secondary' onClick={onChangePassword} disabled={isChangingPassword ? 'disabled' : undefined}>
                    {t('account.Change password')}
                  </button>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </div>
  );
};

const SectionHeading = ({ className, title }) => (
  <h3 className='section-heading'>
    <span>{title}</span>
  </h3>
);

export default AccountPage;
