import React, { useEffect, useState, useContext, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import SVGInline from 'react-svg-inline';
import {
  sizleFileYellow, sizleFileYellowAlt, sizleFileBlue, sizleFileBlueAlt, sizleFilePurple, sizleFilePurpleAlt,
  sizleFileRed, sizleFileRedAlt, sizleFileTeal, sizleFileTealAlt,
} from '@sizle-icons';
import { Spinner, Portal, Popover, PopoverTrigger, PopoverContent, Input, Menu } from '@chakra-ui/core';
import { useMutation } from '@apollo/client';
import { toastr } from 'react-redux-toastr';
import { FiPlus, FiMoreHorizontal, FiSave, FiX } from 'react-icons/fi';

import FolderSubMenu from './FolderSubMenu';
import { RENAME_QUERY, GET_FOLDERS_QUERY } from 'src/graphql/folders';
import { MOVE_PRESENTATION_QUERY } from 'src/graphql/presentations';
import { workspaceContext, useUserContext } from 'src/context';
import UploadDropdown from 'src/modules/controls/components/UploadDropdown';

const ICON_SIZE = 10;
const LEVEL_SPACE = 15;

const ListItem = ({
  newFolderId,
  level = 0,
  hasNodes,
  isOpen,
  label,
  toggleNode,
  focused,
  icon,
  isOriginal,
  onClick,
  onCreateNewFolder,
  ...props
}) => {
  const [menuIcons, toggleShowingIcons] = useState(false);
  const [folderDropOpen, toggleFolderDrop] = useState(false);
  const [presentationDropOpen, togglePresentationDrop] = useState(false);
  const [showingRenameInput, toggleRenameInput] = useState(false);
  const [applyingRename, setApplyingRename] = useState(false);
  const [renameInputValue, setRenameInputValue] = useState(label);
  const textInputRef = useRef(null);
  const { refetchPresentations, currentWorkspace } = useUserContext();
  const { workspacesLoading, hasWorkspacePermission } = useContext(workspaceContext);

  const [renameSelectedFolder] = useMutation(RENAME_QUERY, {
    refetchQueries: [{
      query: GET_FOLDERS_QUERY, variables: { workspaceId: currentWorkspace },
    }],
  });

  const [movePresentation, { data: movePresentationResult }] = useMutation(MOVE_PRESENTATION_QUERY);

  const toggleMenuIcon = (open) => {
    if (!folderDropOpen && !presentationDropOpen) {
      toggleShowingIcons(open);
    }
  };

  const toggleRenamePopover = (open) => {
    toggleRenameInput(open);
    setRenameInputValue(label);
  };

  const commitRename = async () => {
    toggleRenameInput(false);
    setApplyingRename(true);
    try {
      const { data } = await renameSelectedFolder({
        variables: {
          folderId: props.folderId,
          folderName: renameInputValue,
        },
      });

      if (data?.update_folders) {
        toastr.info('Folder renamed');
      } else {
        toastr.error('Error renaming folder.');
      }
    } catch (e) {
      toastr.error('Error renaming folder.');
    }
    setApplyingRename(false);
  };

  const onRenameInputKeyPress = async (e) => {
    if (e.key === 'Enter') {
      commitRename();
    }
  };

  const onRenameInputChange = (value) => {
    setRenameInputValue(value);
  };

  if (icon === null) {
    icon = 'sizleFilePurple';
  }

  const onClickTreeItem = (e) => {
    e.stopPropagation(); // Prevent click from triggering parent handlers
    onClick({ key: props.folderId, label, hasNodes, toggleNode });
    if (hasNodes) {
      toggleNode();
    }
  };

  const itemDrop = (ev) => {
    const movingPresentationId = ev.dataTransfer.getData('presentationId');
    if (movingPresentationId) {
      movePresentation({
        variables: {
          presentationId: movingPresentationId,
          newFolderId: props.folderId,
        },
      });
    }
  };

  useEffect(() => {
    if (movePresentationResult) {
      refetchPresentations();
    }
  }, [refetchPresentations, movePresentationResult]);

  const folderDragOver = (ev) => {
    ev.dataTransfer.dropEffect = 'move';
    ev.preventDefault();
  };

  return (
    <StyledGroupItem
      id={`folder${props.folderId}`}
      className={`${props.folderId === newFolderId ? 'new-folder' : ''}`}
      isFocused={focused}
      level={level}
      onDragOver={folderDragOver}
      onDrop={itemDrop}
      onMouseEnter={() => toggleMenuIcon(true)}
      onMouseLeave={() => toggleMenuIcon(false)}
      style={{
        paddingLeft: -7 + ICON_SIZE + level * LEVEL_SPACE,
      }}
      {...props}
    >
      {hasNodes ? (
        <StyledChildGroupItem
          className={`${focused && workspacesLoading ? 'loading-spinner' : ''}`}
          onClick={(e) => { e.stopPropagation(); toggleNode(); }}
        >
          <TreeToggleIcon onClick={(e) => { e.stopPropagation(); toggleNode(); }}>
            <SVGInline svg={isOpen
              ? '<svg xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6"><path fill-rule="nonzero" d="M.426.5l5 5 5-5z"/></svg>'
              : '<svg xmlns="http://www.w3.org/2000/svg" width="5" height="10" viewBox="0 0 5 10"><path fill-rule="nonzero" d="M0 10l5-5-5-5z"/></svg>'}
            />
          </TreeToggleIcon>
        </StyledChildGroupItem>
      ) : (
        <TreeToggleIconEmpty className={`${focused && workspacesLoading ? 'loading-spinner' : ''}`} isRoot={level === 0} />
      )}
      <GroupContainer onClick={onClickTreeItem}>
        <GroupIcon iconName={icon} isFocused={focused} />
        <GroupLabel>{label}</GroupLabel>
      </GroupContainer>
      {!applyingRename && menuIcons && (
        <SubMenuIcons className="submenu-icons">
          <Popover
            placement="bottom"
            onOpen={() => toggleFolderDrop(true)}
            onClose={() => toggleFolderDrop(false)}
            isOpen={folderDropOpen}
          >
            <PopoverTrigger>
              <button className="btn ghost tiny">
                <FiMoreHorizontal />
              </button>
            </PopoverTrigger>

            <Portal container={document.querySelector('#root')}>
              <PopoverContent
                zIndex={1001}
                width="112px"
                backgroundColor="var(--background-color)"
                borderColor="var(--input-border-color)"
                borderWidth="1px"
                _focus={{
                  outline: 'none',
                }}
              >
                <FolderSubMenu
                  label={label}
                  isRemovable={!isOriginal}
                  folderId={props.folderId}
                  parentId={props.parent}
                  icon={icon}
                  triggerRenamePopover={toggleRenamePopover}
                  triggerCloseMenu={() => toggleFolderDrop(false)}
                />
              </PopoverContent>
            </Portal>
          </Popover>

          <Popover
            placement="left-start"
            onOpen={() => togglePresentationDrop(true)}
            onClose={() => togglePresentationDrop(false)}
            isOpen={presentationDropOpen}
          >


            <Portal container={document.querySelector('#root')}>
              <PopoverContent
                zIndex={1001}
                width="112px"
              >
                <Menu isOpen autoSelect={false}>
                  <UploadDropdown
                    onCreateNewFolder={onCreateNewFolder}
                    folderId={props.folderId}
                    onOpen={() => togglePresentationDrop(true)}
                    onClose={() => togglePresentationDrop(false)}
                  />
                </Menu>
              </PopoverContent>
            </Portal>
          </Popover>
        </SubMenuIcons>
      )}
      {applyingRename && <FolderSpinner />}
      {showingRenameInput && (
        <Popover
          placement="bottom-start"
          onOpen={() => toggleRenamePopover(true)}
          onClose={() => toggleRenamePopover(false)}
          isOpen={showingRenameInput}
          initialFocusRef={textInputRef}
          closeOnBlur={false}
          closeOnEsc
        >
          <StyleRenamePopover
            zIndex={4}
            _focus={{
              outline: 'none',
            }}
          >
            <Input
              type="text"
              height="2rem"
              pl="0.5rem"
              value={renameInputValue}
              focusBorderColor="none"
              color="var(--text-color)"
              ref={textInputRef}
              onBlur={(e) => {
                if (e.relatedTarget?.name === 'save-commit') {
                  return;
                }
                toggleRenamePopover(false);
              }}
              onChange={(e) => onRenameInputChange(e.target.value)}
              onKeyPress={onRenameInputKeyPress}
            />

          </StyleRenamePopover>
        </Popover>
      )}
    </StyledGroupItem>
  );
};

const GroupIcon = ({ iconName, isFocused }) => {
  let icon;
  switch (iconName) {
    case 'sizleFileYellow':
      icon = isFocused ? sizleFileYellow : sizleFileYellowAlt;
      break;
    case 'sizleFileBlue':
      icon = isFocused ? sizleFileBlue : sizleFileBlueAlt;
      break;
    case 'sizleFilePurple':
      icon = isFocused ? sizleFilePurple : sizleFilePurpleAlt;
      break;
    case 'sizleFileRed':
      icon = isFocused ? sizleFileRed : sizleFileRedAlt;
      break;
    case 'sizleFileTeal':
      icon = isFocused ? sizleFileTeal : sizleFileTealAlt;
      break;
    default:
      break;
  }

  return (
    <StyledGroupIcon className="folder-icon" svg={icon} />
  );
};

const StyledGroupItem = styled.div`
  height: 2.4em;
  background-color: ${props => (props.isFocused ? 'var(--background-highlight-color)' : 'transparent')};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: var(--background-highlight-color);
    border-radius: 6px;
  }

  &:hover .submenu-icons,
  ${props => props.isFocused && '.submenu-icons'} {
    display: flex;
  }
`;

const StyledChildGroupItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GroupLabel = styled.span`
  font-size: 0.95rem;
  padding-left: 0.2em;
  font-weight: 400;
  margin-left: 0px;
  color: var(--text-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const GroupContainer = styled.div`
  overflow: hidden;
  height: 100%;
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  cursor: pointer;
`;

const StyledGroupIcon = styled(SVGInline)`
  margin-bottom: 0px;
`;

const SubMenuIcons = styled.div`
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translateY(-50%);
  display: flex;

  & > * {
    padding: 0px; /* Uniform padding for buttons */
    border: none !important; /* Explicitly force no border */
    outline: none !important; /* Remove focus outline if present */
    border-radius: 6px; /* Circular button styling */
    cursor: pointer;
    background: transparent; /* Ensure no background shadows or effects */

    &:hover {
      border: none !important; /* Ensure no border on hover */
      outline: none !important; /* Remove any hover focus outline */
      background: var(--hover-background-color, transparent); /* Optional: Hover effect */
    }

    svg {
      fill: var(--icon-color); /* Icon color for consistency */
      width: 16px;
      height: 16px;
    }
  }
`;



const FolderSpinner = styled(Spinner)`
  margin-right: -50px;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
`;

const TreeToggleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin-left: 0.5em;
  scale: 0.7;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    path {
      fill: var(--text-color-light);
      transition: fill 0.2s ease;
    }
  }

  &:hover svg path {
    fill: var(--text-color);
  }
`;

const TreeToggleIconEmpty = styled(TreeToggleIcon)`
  position: relative;
  ${props => props.isRoot
    ? css`
    width: 10px;
    flex-shrink: 0;
  `
    : css`
    min-width: 20px;
  `}
`;

const StyleRenamePopover = styled(PopoverContent)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 300px;
  background-color: var(--input-background);
  color: var(--text-color);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.30);
  border-radius: 8px;
  border: none;
  transition: all 0.3s ease-in-out;

  input {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--text-color);
    font-size: 0.875rem;
    transition: border-color 0.2s ease-in-out;

    &:focus {
      outline: none;
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
      flex: 1; /* Ensure both buttons take equal width */
      padding: 10px;
      border: none;
      border-radius: 6px;
      font-size: 0.9rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &.cancel {
        background-color: var(--danger-background-color);
        color: var(--danger-text-color);
      }

      &.save {
        background-color: var(--primary-color);
        color: white;
      }

      &:hover {
        background-color: var(--hover-background-color);
      }
    }
  }
`;


export default ListItem;
