import * as React from 'react'

function SvgInfo (props) {
  return (
    <svg
      stroke='currentColor'
      fill='none'
      strokeWidth={2}
      viewBox='0 0 24 24'
      strokeLinecap='round'
      strokeLinejoin='round'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={12} cy={12} r={10} />
      <path d='M12 16v-4M12 8h0' />
    </svg>
  )
}

export default SvgInfo
