import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import BeatLoader from 'react-spinners/BeatLoader';
import { useTranslation } from 'react-i18next';

import CustomTable from './CustomTable';
import { ModeContext, useUserContext } from 'src/context';
import DashboardNotice from 'src/modules/presentations/components/DashboardNotice';

const UserPresentations = ({ presentations = [], isLoading, areSidebarsCollapsed }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [presentationsPerPage, setPresentationsPerPage] = useState(15); // Set to 12 presentations per page.
  const containerRef = useRef(null);

  useEffect(() => {
    setTotalPages(Math.ceil(presentations.length / presentationsPerPage));
  }, [presentations.length, presentationsPerPage, areSidebarsCollapsed]);

  useEffect(() => {
    const handleResize = () => {
      setPresentationsPerPage(15); 
    };

    // Update presentation count on resize
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(presentations.length / presentationsPerPage));
  }, [presentations.length, presentationsPerPage]);

  const indexOfLastPresentation = currentPage * presentationsPerPage;
  const indexOfFirstPresentation = indexOfLastPresentation - presentationsPerPage;
  const currentPresentations = presentations.slice(indexOfFirstPresentation, indexOfLastPresentation);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [activeFilter, setActiveFilter] = useState('Last edited');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const { mode } = useContext(ModeContext);
  const { setNotificationView, notificationView } = useUserContext();
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChangeFilter = (newFilter) => {
    setActiveFilter(newFilter);
  };

  const filterItems = [
    { name: t('inputs.Last created'), value: 'Last created' },
    { name: t('inputs.Last edited'), value: 'Last edited' },
    { name: t('inputs.Name (A-Z)'), value: 'Name (A-Z)' },
  ];

  const sortedPresentations = currentPresentations
    ? currentPresentations.slice().sort((a, b) =>
        activeFilter === 'Last edited'
          ? new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
          : activeFilter === 'Last created'
          ? new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          : a.title.localeCompare(b.title)
      )
    : [];

  return (
    <PresentationsWrapper ref={containerRef}>
      <PresentationsContainer>
        <CustomTable presentations={sortedPresentations} isLoading={isLoading} />
        {!sortedPresentations.length && !isLoading && <DashboardNotice />}
        {isLoading && <BeatLoader color={mode === 'dark' ? '#fff' : '#333'} />}
        {totalPages > 1 && (
          <Pagination>
            <PaginationButton onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </PaginationButton>
            <PaginationInfo>
              Page {currentPage} of {totalPages}
            </PaginationInfo>
            <PaginationButton onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </PaginationButton>
          </Pagination>
        )}
      </PresentationsContainer>
    </PresentationsWrapper>
  );
};

const PresentationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  padding: 0px 0px 0px 20px;
  position: relative;
  background-color: var(--background-color);
`;

const PresentationsContainer = styled.div`
  display: flex;
  padding-top: 1em;
  padding-right: 1.5em;
  padding-left: 0.5em;
  flex-direction: column;
  position: relative;
  z-index: 0; /* Ensures it is above the popup but below other content */
`;

const PresentationCard = styled.div`
  display: flex;
  justify-content: space-between; /* Pushes the options button to the right */
  align-items: center; /* Aligns content vertically */
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: var(--card-background-color);
`;

const CardContent = styled.div`
  flex-grow: 1;
`;

const OptionsButton = styled.button`
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  margin-left: auto; /* Ensures the button is at the right edge */
`;

const Pagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 0; /* Lower z-index to stay behind the popup */
  position: relative;
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  color: var(--text-color);
  padding: 7px 15px;
  border-radius: 100px; /* More rounded corners */
  transition: background 0.3s, box-shadow 0.3s, border 0.3s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;  
    border: 1px solid var(--border-color-light); /* Light border for disabled state */
  }

  &:hover {
    background-color: var(--primary-color-vfaint);
  }
`;

const PaginationInfo = styled.span`
  margin: 0 10px;
  color: #ccc;
`;

export default UserPresentations;
