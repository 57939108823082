import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { subscriptionContext } from 'src/context'
import { differenceInDays } from 'date-fns'

export const ProTrialBanner = ({ ...props }) => {
  const { currentPlan, trial } = useContext(subscriptionContext)

  const currentPlanType = (currentPlan?.planType || '').toLowerCase() || 'plan'
  const currentPlanTier = (currentPlan?.planTier || '').toLowerCase() || 'expired solo'
  const daysRemaining = differenceInDays(trial?.expiry, new Date())

  return (currentPlanType === 'trial')
    ? (
      <ProTrialContainer className='protrial-banner' {...props}>
 <span>{daysRemaining} more day{daysRemaining !== 1 ? 's' : ''} left on your trial. <Link className='click-text' to='/billing'>Upgrade now</Link> to enjoy full access and premium features.</span>
      </ProTrialContainer>
    )
    : (currentPlanTier === 'expired solo')
      ? (
        <ProTrialContainer className='protrial-banner' {...props}>
          <span>Your trial has ended. <Link className='click-text' to='/billing'><u>Choose a plan to continue using Sizle.</u></Link></span>
        </ProTrialContainer>
      )
      : (currentPlanTier === 'solo')
        ? (
          <ProTrialContainer className='protrial-banner' {...props}>
            <span>You are on a Solo plan. <Link className='click-text' to='/billing'>Explore Pro features</Link>.</span>
          </ProTrialContainer>
        )
        : (<div />)
}

const ProTrialContainer = styled.div`
  padding: 12px 20px 12px 21px;
  font-size: 2rem;
  border-radius: 6px;
  border-width: 1px;
  line-height: 1em;
  background-color: #FFE9BC;
  border-color: rgba(224, 30, 90, .4);
  display: flex;
  flex-direction: row;
  color: #000000;
  .close-button {
    width: 14px;
    height: 14px;
    margin-left: 20px;
    margin-top: -1px;
    margin-bottom: -2px;
    &:not(.loading-spinner) {
      &::after,&::before {
        transform: translateY(-6px) rotate(45deg);
        border-left: 1px solid #F9F9F9;
        content: '';
        height: 14px;
        width: 1px;
        position: absolute;
      }
      &::after {
        transform: translateY(-6px) rotate(-45deg);
      }
    }
  }
  .click-text {
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }

  }
  `
