import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'src/modules/modals';
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/core';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useAnalytics } from 'use-analytics';
import { toastr } from 'react-redux-toastr';
import { sendAmplitudeData } from 'src/utils/amplitude';
import { useTranslation } from 'react-i18next';
import { workspaceContext } from 'src/context';

const AvatarImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .avatar {
    margin-right: 10px;
    width: 85%;
    height: auto;
  }

  input[type='file'] {
    display: none;
  }

  .secondary {
    background-color: #ddd;
    color: black;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #ccc;
    }
  }
`;

function CreateWorkspaceModal({ isOpen, dismissModal }) {
  const [avatarDataURI, setAvatarDataURI] = useState(null);
  const { workspaces, createWorkspace } = useContext(workspaceContext);
  const [loading, setLoading] = useState(false);
  const { track } = useAnalytics();
  const { t } = useTranslation();
  const { setModal } = useModal();
  const navigate = useNavigate();

  const submitNewWorkspace = async (event) => {
    event.preventDefault();
    const name = event.target.name.value.trim();

    if (!name) {
      toastr.error(t('Workspace name is required.'));
      return;
    }

    setLoading(true);
    try {
      await createWorkspace(name, event.target.avatar.files[0]);
      toastr.success(t('Your workspace has been created successfully.'));
      track('workspace-created');
      sendAmplitudeData('WORKSPACE_CREATED');
      setModal(null);
      navigate(`/s/${workspaces.length}/presentations`);
    } catch (e) {
      toastr.error(t('An error occurred while creating your workspace.'));
      track('workspace-create-error');
    } finally {
      setLoading(false);
    }
  };

  const updatedAvatar = (event) => {
    const fileReader = new FileReader();
    fileReader.onload = () => setAvatarDataURI(fileReader.result);
    fileReader.onerror = () => toastr.error(t('Failed to load image.'));
    fileReader.readAsDataURL(event.target.files[0]);
  };

  return (
    <Modal
      size="md"
      isCentered
      closeOnOverlayClick
      closeOnEsc
      isOpen={isOpen}
      onClose={dismissModal}
    >
      <ModalOverlay />
      <ModalContent
        rounded="lg"
        backgroundColor="var(--background-alt-color)"
        boxShadow="var(--modal-shadow)"
        style={{ padding: '1rem', textAlign: 'center' }}
      >
        <h3 className="title" style={{ fontWeight: 'bolder', marginBottom: '2.5px' }}>
          {t('Create a new workspace')}
        </h3>
        <p style={{ marginBottom: '5px', color: 'var(--text-color-light)' }}>
          {t('Set up a shared workspace and invite your team.')}
        </p>
        <form onSubmit={submitNewWorkspace} style={{ padding: '0 20px' }}>
          <div className="form-control" style={{ marginBottom: '20px' }}>
            <input
              className="lg"
              type="text"
              name="name"
              id="workspaceName"
              placeholder={t('e.g. "Marketing Team"')}
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid var(--border-color)',
                borderRadius: '6px',
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <AvatarImage>
              {avatarDataURI && (
                <img className="avatar" src={avatarDataURI} alt={t('New Workspace')} />
              )}
              <div>
                <input
                  onChange={updatedAvatar}
                  id="workspace-create-avatar"
                  name="avatar"
                  type="file"
                />
                <label className="btn secondary" htmlFor="workspace-create-avatar">
                  {t('Upload avatar')}
                </label>
              </div>
            </AvatarImage>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <button
              className="btn"
              type="submit"
              disabled={loading}
            >
              {loading ? t('Loading...') : t('Create workspace')}
            </button>
          </div>
        </form>
        <ModalCloseButton color="primary" style={{ position: 'absolute', top: '10px', right: '10px' }} />
      </ModalContent>
    </Modal>
  );
}

CreateWorkspaceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dismissModal: PropTypes.func.isRequired,
};

export default CreateWorkspaceModal;
