import React, { useContext, useState } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'
import { sendAmplitudeData } from 'src/utils/amplitude'
import { workspaceContext, useUserContext } from 'src/context'

const MainProfileForm = () => {
  const { user } = useUserContext()
  const [isUpdatingProfile, toggleUpdatingProfile] = useState(false)
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false)
  const { updateUser, updateUserAvatar, setAvatarCacheTimestamp, avatarCacheTimestamp } = useUserContext()
  const { refetchWorkspaces } = useContext(workspaceContext)
  const { t } = useTranslation()
  const [phoneValue, setPhoneValue] = useState(user?.phone_number)

  const updateUserProfile = async (e: React.FormEvent<HTMLFormElement>) => {
    toggleUpdatingProfile(true)
    e.preventDefault()
    await updateUser({
      firstName: (e.currentTarget.elements.namedItem('firstName') as HTMLInputElement).value || undefined,
      lastName: (e.currentTarget.elements.namedItem('lastName') as HTMLInputElement).value || undefined,
      phoneNumber: phoneValue
    })

    toggleUpdatingProfile(false)
    toastr.info(t('toastr.Your profile has been updated'))
    sendAmplitudeData('USER_PROFILE_UPDATED')
  }

  const submitAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploadingAvatar(true)
    await updateUserAvatar(e.target.files?.[0])
    await refetchWorkspaces()
    setAvatarCacheTimestamp(new Date())
    setIsUploadingAvatar(false)
  }

  return (
    <form onSubmit={updateUserProfile}>
      <div className='main-profile-wrapper'>
      <div className="page-header">
        <h1>Account</h1>
      </div>
        <div className='main-profile'>
          <PictureInput className='icon'>
            <br></br>
          <h5>{t('Your avatar')}</h5>
            <AvatarImage background={`/api/users/${user?.sub}/avatar?timestamp=${avatarCacheTimestamp.getMilliseconds()}`}>
              <div className='image' />
              <div>
                <input onChange={submitAvatar} id='user-profile-avatar' name='avatar' type='file' />
                <label className='btn' loading={isUploadingAvatar ? 'loading' : undefined} htmlFor='user-profile-avatar'>Upload</label>
              </div>
            </AvatarImage>
          </PictureInput>
          <FormField className='email'>
            <FormLabel htmlFor='email'>{t('Your email address')}</FormLabel>
            <FormInput
              disabled
              id='email'
              name='email'
              placeholder={t('inputs.Enter your email')}
              type='email'
              defaultValue={user?.email}
            />
          </FormField>
          <FormField className='phone'>
            <FormLabel htmlFor='phoneNumber'>{t('Phone')}</FormLabel>
            <StyledPhoneInput
              onChange={setPhoneValue}
              name='phoneNumber'
              id='phoneNumber'
              displayInitialValueAsLocalNumber
              defaultCountry='AU'
              placeholder='Enter mobile number'
              value={phoneValue}
            />
          </FormField>
          <FormField className='family-name'>
            <FormLabel htmlFor='lastName'>{t('Last name')}</FormLabel>
            <FormInput
              id='lastName'
              name='lastName'
              type='text'
              defaultValue={user?.family_name}
            />
          </FormField>
          <FormField className='given-name'>
            <FormLabel htmlFor='firstName'>{t('First name')}</FormLabel>
            <FormInput
              id='firstName'
              name='firstName'
              type='text'
              defaultValue={user?.given_name}
            />
          </FormField>
          <FormButton className={`update-profile ${isUpdatingProfile ? 'loading-spinner' : ''}`} type='submit'>{t('account.Update profile')}</FormButton>
        </div>
      </div>
    </form>

  )
}

const FormField = styled.div`
  width: 100%;
  margin: 0 0 15px;
`

const FormLabel = styled.label`
  display: block;
  margin: 0 0 7px;
  font-size: 1rem; 
  font-weight: 400; 
  color: var(--text-color);}
`

const FormInput = styled.input`
  display: block;
  width: 100%;
  border-radius: px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  background-color: var(--input-background);
  color: var(--input-color);
  padding: 6px 10px;
  font-size: 13px;
  line-height: 1.56;

  &:focus {
    border-color: var(--input-focus);
  }
  &.readonly {
    color: #cfcfcf;
    cursor: not-allowed;
    &:focus {
      border-color: #E8E8E9;
    }
  }
  &:disabled {
    opacity: 0.5;
  }
`

const PictureInput = styled.div`
  padding-bottom: 25px;
  > .label {
    padding-top: 0;
    padding-bottom: 9px;
  }
`

const AvatarImage = styled.div(props => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  .image {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #EEE url(${props.background}) no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 10px;
  }
  input[type="file"] {
    display: none;
  }
`)

const FormButton = styled.button`
  border: 1px solid var(--border-color-light);
  color: var(--white-color);
  font-weight: 500;
  background: linear-gradient(135deg, #5d55fa, #3e38ae);
  border-radius: 6px;
  padding: 5px 13px;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(6px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15), inset 0 1px 0 rgba(255, 255, 255, 0.1);

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    background: var(--primary-color-shade);
    box-shadow: none;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--input-border-color);
  background-color: var(--input-background);
  color: var(--input-color);
  padding: 6px 10px;

  input {
    border: 0;
    display: block;
    background-color: transparent;
    font-size: 13px;
    line-height: 1.56;
    color: var(--input-color);
  }
`

export default MainProfileForm
